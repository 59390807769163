import React, { useRef } from 'react'

// Libraries
import { Col, Container, Navbar, Row } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import { m, AnimatePresence } from 'framer-motion';
import { Formik, Form } from 'formik';
import emailjs from "@emailjs/browser";

// Components
import {
  Header,
  HeaderCart,
  HeaderLanguage,
  HeaderNav,
  Menu,
  SearchBar,
} from "../../Components/Header/Header";
import { fadeIn } from '../../Functions/GlobalAnimations';
import { resetForm, sendEmail } from "../../Functions/Utilities";
import Buttons from '../../Components/Button/Buttons'
import { Checkbox, Input, TextArea } from '../../Components/Form/Form'
import { ContactFormStyle03Schema, } from '../../Components/Form/FormSchema';
import MessageBox from '../../Components/MessageBox/MessageBox';
import FooterStyle01 from "../../Components/Footers/FooterStyle01";

const ReactDeveloper = (props) => {
  const sendEmail = (form, setStatus) => {
    emailjs
      .sendForm(
        "service_88dgsm5", // Replace with your EmailJS Service ID
        // "service_88dgsm4",    // Replace with your EmailJS Service ID
        "template_zf7y7kg",   // Replace with your EmailJS Template ID
        form.current,         // Form reference
        "QPcCLtghmJ4A41uCg"   // Replace with your EmailJS Public Key
      )
      .then(
        (result) => {
          // console.log("Email sent successfully:", result.text);
          if (result.text === 'OK') {
            alert("Your message has been sent successfully!");
            setStatus({ success: true });
          }
        },
        (error) => {
          // console.error("Error sending email:", error.text);
          if (error.text) {
            alert("Failed to send the message. Please try again.");
          }
        }
      );
  };
  const form = useRef(null)

  return (
    <div style={props.style}>
      <Header topSpace={{ md: true }} type="reverse-scroll">
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="px-[35px] py-[0px] md:px-0"
        >
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0 xs:px-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="111"
                  height="36"
                  loading="lazy"
                  src="/assets/img/webp/logo-gradient-sky-blue-pink.webp"
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <div className="col-auto hidden order-last md:block">
            <Navbar.Toggle className="md:ml-[10px] sm:ml-0">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
          </div>
          <Navbar.Collapse className="col-auto px-0 justify-end">
            <Menu {...props} />
          </Navbar.Collapse>
          <Col className="col-auto text-right pe-0">
            <SearchBar className="pr-0" />
            <HeaderLanguage />
            {/* <HeaderCart /> */}
          </Col>
        </HeaderNav>
      </Header>

      {/* Section Start */}
      <section
        style={{
          background: "linear-gradient(to right, #000000, #4b3621)", // Black to brown gradient
          color: "#fff", // White text
          padding: "100px 0", // Adjust padding for vertical alignment
          textAlign: "center", // Center text
        }}
      >
        <Container>
          <Row>
            <Col>
              <h1
                style={{
                  fontFamily: "'Times New Roman', serif", // Serif font
                  fontSize: "3rem", // Large font size
                  fontWeight: "bold", // Bold text
                }}
              >
                React Developer
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}




      <m.section className="py-[40px] lg:py-[120px] md:py-[75px] sm:py-[50px] xs:py-[80px] xxs:py-[50px]" {...fadeIn}>
        <Container>

          <Row className="justify-center">
            <Col lg={8} md={10} sm={12}>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px]">
                Job Description
              </h6>
              <p className="text-md text-gray-700 mb-[20px] ">
                We are looking for a great JavaScript developer who is proficient with React.js. Your primary focus will be on developing user interface components and implementing them following well-known React.js workflows (such as Flux or Redux). You will ensure that these components and the overall application are robust and easy to maintain. You will coordinate with the rest of the team working on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and quality product is important.
              </p>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px]">
                Responsibilities
              </h6>
              <ul className="list-disc pl-[20px] text-gray-700 mb-[20px]">
                <li>Developing new user-facing features using React.js</li>
                <li>Building reusable components and front-end libraries for future use</li>
                <li>Translating designs and wireframes into high quality code</li>
                <li> Optimizing components for maximum performance across a vast array of web-capable devices and browsers.</li>
              </ul>
              <h6 className="font-serif text-gray-900 font-medium mb-[20px] ">
                Skills
              </h6>
              <ul className="list-disc pl-[20px] text-gray-700 mb-[20px]">
                <li>Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model</li>
                <li>Thorough understanding of React.js and its core principles</li>
                <li>Experience with popular React.js workflows (such as Flux or Redux)</li>
                <li>Familiarity with newer specifications of EcmaScript</li>
                <li>Experience with data structure libraries (e.g., Immutable.js)</li>
                <li>Knowledge of isomorphic React is a plus</li>
                <li>Familiarity with RESTful APIs</li>
                <li>Knowledge of modern authorization mechanisms, such as JSON Web Token</li>
                <li>Familiarity with modern front-end build pipelines and tools</li>
                <li>Experience with common front-end development tools such as Babel, Webpack, NPM, etc.</li>
                <li>Ability to understand business requirements and translate them into technical requirements.</li>
                <li>A knack for benchmarking and optimization</li>
                <li>Familiarity with code versioning tools such as Git, SVN, and Mercurial</li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col className='mb-[3%]'>
              <h4 className="font-serif text-gray-900 text-center font-medium mb-[-10px]">Quick Form</h4>
            </Col>
          </Row>
          <Row className="justify-center">
            <Col>
              <Formik
                initialValues={{ name: '', email: '', phone: '', message: '', file: null }}
                validationSchema={ContactFormStyle03Schema}
                onSubmit={async (values, { setSubmitting, resetForm, setStatus }) => {
                  // console.log("Form Values:", values);
                  setSubmitting(true);
                  // Call the sendEmail function with the form reference
                  sendEmail(form, setStatus);
                  setSubmitting(false);
                  resetForm();
                  // setStatus({ success: true });
                }}
              >
                {({ isSubmitting, status, setFieldValue }) => (
                  <Form ref={form}>
                    <Row className="row-cols-1 row-cols-md-2">
                      <Col className="mb-16 sm:mb-[25px]">
                        <Input
                          showErrorMsg={false}
                          type="text"
                          name="name"
                          className="py-[15px] px-[20px] text-md w-full border-[1px] border-solid border-[#dfdfdf]"
                          labelClass="mb-[25px]"
                          placeholder="Your name"
                        />
                        <Input
                          showErrorMsg={false}
                          type="email"
                          name="email"
                          className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                          labelClass="mb-[25px]"
                          placeholder="Your email address"
                        />
                        <Input
                          showErrorMsg={false}
                          type="tel"
                          name="phone"
                          className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                          labelClass="mb-[25px]"
                          placeholder="Mobile number"
                        />
                        <input
                          type="file"
                          name="file"
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue("file", file); // Set file value
                          }}
                          className="py-[15px] px-[20px] w-full text-md border-[1px] border-solid border-[#dfdfdf]"
                        />

                      </Col>
                      <Col className="mb-16 sm:mb-[20px]">
                        <Input
                          className="border-[1px] border-solid border-[#dfdfdf] w-full py-[15px] px-[20px] text-md h-[210px] resize-none"
                          rows="6"
                          name="comment"
                          placeholder="Your message"
                        />
                      </Col>
                      <Col className="text-left sm:mb-[20px]">
                        <Checkbox
                          type="checkbox"
                          name="terms_condition"
                          className="inline-block mt-[4px]"
                          labelClass="flex items-start"
                        >
                          <span className="ml-[10px] text-sm inline-block w-[85%]">
                            I accept the terms & conditions and I understand
                            that my data will be hold securely in accordance
                            with the
                            <Link
                              aria-label="checkbox"
                              to="/privacy"
                              target="_blank"
                              className="text-darkgray underline hover:text-fastblue"
                            >
                              {" "}
                              privacy policy
                            </Link>
                            .
                          </span>
                        </Checkbox>
                      </Col>
                      <Col className="text-right sm:text-center">
                        <Buttons
                          type="submit"
                          className={`text-xs tracking-[1px] rounded-none py-[12px] px-[28px] uppercase${isSubmitting ? " loading" : ""}`}
                          themeColor={["#b884fd", "#fe73a8", "b884fd"]}
                          size="md"
                          color="#fff"
                          title="Apply"
                        />
                      </Col>
                    </Row>
                    <AnimatePresence>
                      {status && (
                        <Row>
                          <Col xs={12}>
                            <div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                            >
                              <MessageBox
                                className="mt-[20px] py-[10px]"
                                theme="message-box01"
                                variant="success"
                                message="Your message has been sent successfully!"
                              />
                            </div>
                          </Col>
                        </Row>
                      )}
                    </AnimatePresence>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </m.section>

      {/* Section Start */}
      <FooterStyle01
        theme="dark"
        className="bg-[#262b35] text-slateblue overflow-visible"
      />
      {/* Section End */}


    </div>
  )
}

export default ReactDeveloper;
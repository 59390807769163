import React, { memo } from "react";

// Libraries
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { PropTypes } from "prop-types";

// Components
import SocialIcons from "../SocialIcon/SocialIcons";
import FooterMenu, { Footer } from "./Footer";
import StaticInstagram from "../Instagram/StaticInstagram";

// Data
import FooterData from "./FooterData";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  {
    color: "#828282",
    link: "https://dribbble.com/",
    icon: "fab fa-dribbble",
  },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const FooterStyle01 = (props) => {
  return (
    <Footer
      theme={props.theme}
      className={`${props.className ? ` ${props.className}` : ""}`}
    >
      <div className="py-[3%] lg:py-[95px] md:py-[50px]">
        <Container>
          <Row className="justify-between"> {/* Added justify-between */}
            <Col lg={3} sm={6} className="xs:mb-[25px]">
              <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                Get in touch
              </span>
              <p className="w-[85%] mb-[15px]">
                41-B, BA Block, Janak Puri, New Delhi-110058
              </p>
              <div>
                <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                +011-49126856
              </div>
              <div>
                <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                <a aria-label="mail" href="mailTo:info@belinnov.com">
                  info@belinnov.com
                </a>
              </div>
            </Col>
            <Col lg={3} sm={6} className="xs:mb-[25px]">
              <Link
                to="https://dunsregistered.dnb.com/DunsRegisteredProfileAnywhere.aspx?Key1=3179455&PaArea=Email"
                target="_blank"
                rel="noopener noreferrer"
                className="text-slateblue mb-[15px] mt-[5px] inline-block"
              >
                <img
                  loading="lazy"
                  src="/assets/img/webp/Belinnov-Solutions-Private-Limited-Siganture.jpg"
                  alt="logo"
                  width="250"
                  height="36"
                />
              </Link></Col>
            <Col lg={3} sm={6} className="text-right xs:mb-[25px]"> {/* Aligned to right */}
              <FooterMenu
                data={FooterData.slice(0, 2)}
                className="xl:px-[15px] md:mb-[40px] xs:mb-[25px]"
                titleClass="capitalize"
              />
            </Col>
          </Row>

        </Container>
      </div>
      <div className="py-[30px] border-t border-[#ffffff1a]">
        <Container>
          <Row>
            <Col md={3} className="sm:mb-[20px]">
              <Link
                aria-label="homepage"
                to="/"
                className="sm:flex sm:justify-center"
              >
                <img alt="logo" src={props.logo} width="111" height="36" />
              </Link>
            </Col>
            <Col
              md={6}
              className="flex justify-center items-center text-center sm:mb-[20px]"
            >
              <p className="mb-0">
                &copy; {new Date().getFullYear()} Belinnov Solutions
              </p>
            </Col>
            <Col md={3} className="text-right sm:text-center">
              <SocialIcons
                size="xs"
                theme="social-icon-style-01"
                className="justify-end sm:justify-center"
                iconColor={props.theme === "dark" ? "light" : "dark"}
                data={iconData}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Footer>
  );
};

FooterStyle01.defaultProps = {
  data: FooterData,
  className: "bg-darkgray text-[#828282]",
  logo: "/assets/img/webp/logo-gradient-sky-blue-pink.webp",
  theme: "light",
};

FooterStyle01.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};

export default memo(FooterStyle01);
